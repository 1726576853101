import { Application } from '@hotwired/stimulus'
import * as Turbo from '@hotwired/turbo'

import '../styles/tailwind.compiled.css'
import '../styles/fonts.css'

import MenuController from './main/menu_controller'
import FlashController from './main/flash_controller'
import ChartByDateController from './main/chart_by_date_controller'
import ChartByThousandsController from './main/chart_by_thousands_controller'

window.Stimulus = Application.start()
window.Stimulus.register('menu', MenuController)
window.Stimulus.register('flash', FlashController)
window.Stimulus.register('chart-by-date', ChartByDateController)
window.Stimulus.register('chart-by-thousands', ChartByThousandsController)

Turbo.start()
