/* eslint-disable @typescript-eslint/explicit-member-accessibility */
import { Controller } from '@hotwired/stimulus'

class FlashController extends Controller<HTMLFormElement> {
  static targets = ['message']

  declare readonly messageTargets: HTMLDivElement[]

  destroy(event) {
    const messageTarget = this.messageTargets.find((el) => el.contains(event.currentTarget))

    if (messageTarget) {
      messageTarget.remove()
    }
  }
}

export default FlashController
