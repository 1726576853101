/* eslint-disable @typescript-eslint/explicit-member-accessibility */
import { Controller } from '@hotwired/stimulus'

class MenuController extends Controller<HTMLDivElement> {
  static targets = ['button', 'menu']

  declare readonly buttonTarget: HTMLButtonElement
  declare readonly menuTarget: HTMLDivElement

  toggle() {
    this.menuTarget.classList.toggle('!visible')
    this.menuTarget.classList.toggle('!opacity-100')
  }

  outside(event) {
    if (this.buttonTarget === event.target || this.buttonTarget.contains(event.target)) return

    this.close()
  }

  open() {
    this.menuTarget.classList.add('!visible', '!opacity-100')
  }

  close() {
    this.menuTarget.classList.remove('!visible', '!opacity-100')
  }
}

export default MenuController
