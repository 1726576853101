/* eslint-disable @typescript-eslint/explicit-member-accessibility */
import { Controller } from '@hotwired/stimulus'
import Chart from 'chart.js/auto'

class ChartByDateController extends Controller<HTMLFormElement> {
  static targets = ['block']

  static values = {
    type: { type: String, default: 'default' },
    input: { type: Object, default: {} },
  }
  declare typeValue: string
  declare inputValue: {
    resolved: { label: string; data: { date: string | null; count: number }[] }
    registered: { label: string; data: { date: string | null; count: number }[] }
  }
  declare readonly blockTarget: HTMLCanvasElement

  connect(): void {
    const { resolved = { label: '...', data: [] }, registered = { label: '...', data: [] } } =
      this.inputValue

    new Chart(this.blockTarget, {
      type: 'line',
      options: {
        maintainAspectRatio: false,
      },
      data: {
        labels: registered.data.map((row) => row.date),
        datasets: [
          {
            label: resolved.label,
            data: resolved.data.map((row) => row.count),
          },
          {
            label: registered.label,
            data: registered.data.map((row) => row.count),
          },
        ],
      },
    })
  }
}

export default ChartByDateController
